import React from 'react'
import { Link } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'

const ModalPreviewCertificate = ({ location }) =>  (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div className="youtubeModal">
        {modal ? (
          <Link style={{float:"right"}} to={closeTo}>
            Close
          </Link>
        ) : (
          <header>
            <h1>
              Preview Certificate
            </h1>
          </header>
        )}

        <div style={{clear:"both"}}>
          <img alt="preview modal" src={location.hash.substring(1)}  />
        </div>

      </div>
    )}
  </ModalRoutingContext.Consumer>
)

export default ModalPreviewCertificate
